<template>
  <LiefengContent>
    <template v-slot:title>服务请求</template>
    <template v-slot:toolsbarRight>
      <Button type="error" icon="ios-arrow-back" @click="$router.push({path:'/serviceorderorgplatform'})">返回</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100, 200]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>

      <!-- 创建工单 -->
      <LiefengModal
        title="创建工单"
        :value="openCreateStatus"
        @input="openCreateStatusFn"
        width="80%"
        height="calc(100vh - 100px)"
      >
        <template v-slot:contentarea>
          <Form
            :model="createFormData"
            :rules="createValidateObj"
            :label-width="90"
            ref="createForm"
            :label-colon="true"
            v-if="openCreateStatus"
          >
            <FormItem label="工单名称" prop="name">
              <Input
                v-model="createFormData.name"
                placeholder="请输入工单名称"
              ></Input>
            </FormItem>

             <FormItem label="服务描述" prop="content">
              <Input
                v-model="createFormData.content"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                placeholder="请填写服务要求，限400字"
                show-word-limit
                :maxlength="400"
              ></Input>
            </FormItem>
            <FormItem class="services" :error="errorServices">
              <span class="validate" slot="label">服务清单：</span>
              <LiefengTable
                :talbeColumns="createTalbeColumns"
                :tableData="serviceList"
                :hidePage="true"
                @tableSelect="createTableSelect"
              ></LiefengTable>
            </FormItem>
            <FormItem label="是否派单" prop="serviceOrder">
              <RadioGroup v-model="createFormData.serviceOrder">
                <Radio label="1">是</Radio>
                <Radio label="2">否</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="服务记录" prop="serviceResult" v-if="createFormData.serviceOrder == '2'">
               <Input
                      v-model="createFormData.serviceResult"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 6 }"
                      placeholder="请填写服务记录"
                    ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="openCreateStatusFn(false)">取消</Button>
          <Button
            type="primary"
            style="margin-left: 10px"
            @click="saveCreateFn"
            >{{ currentCreateStatus ? "提交中..." : "提交" }}</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/serviceorderorgquest')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      that: this,
      talbeColumns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              this.pageSize * (this.page - 1) + params.index + 1
            );
          },
        },
        {
          title: "服务人",
          align: "center",
          width: 120,
          key: "userName",
        },
        {
          title: "服务请求名称",
          align: "center",
          width: 200,
          key: "name",
        },
        
         {
          title: "服务内容",
          align: "center",
          key: "content",
          minWidth: 200,
        },
        {
          title: "生成服务工单",
          align: "center",
          key: "isCreateOrder",
          width: 130,
          render: (h,params) => {
              return h('div',params.row.isCreateOrder ? '是' : '否')
          }
        },
        {
          title: "服务清单列表",
          align: "center",
          minWidth: 200,
          className: 'table-list',
          render: (h, params) => {
            return h(
              "div",
              (() => {
                let result = [];
                if (params.row.serviceApplyItemList && params.row.serviceApplyItemList.length > 0) {
                  this.serviceList.map((item) => {
                    params.row.serviceApplyItemList.map((subItem, subIndex) => {
                      if (item.itemId == subItem.itemId) {
                        result.push(
                          h("div",{
                           style: {
                               lineHeight: '48px',
                               borderBottom: subIndex == params.row.serviceApplyItemList.length -1 ? '' :'1px solid #e8eaec'
                           }
                       }, subIndex + 1 + "." + item.itemName + ";")
                        );
                      }
                    });
                  });
                  return result;
                } else {
                  return "";
                }
              })()
            );
          },
        },
        {
            title: '服务状态',
            width: 120,
            key: 'status',
            align: 'center',
            render: (h,params) => {
                let result = '';
                this.serviceStatusList.map(item => {
                  if(item.dictKey == params.row.status) {
                    result = item.dictValue;
                  }
                })
                return h('div',result)
            }
        },
        {
          title: "服务请求类别",
          align: "center",
          width: 130,
          key: "applyType",
            render: (h,params) => {
            return h('div',params.row.applyType == 1 && '服务请求')
            }
        },
        {
            title: '创建日期',
            width: 180,
            key: 'gmtCreate',
            align: 'center',
            render: (h,params) => {
              return h('div',params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), 'yyyy-MM-dd hh:mm:ss') : '')
            }
        },
        {
            title: '催办',
            width: 160,
            key: 'urgedList',
            align: 'center',
            className: 'table-list',
            render: (h,params) => {
                if(params.row.urgedList && params.row.urgedList.length > 0) {
                   return params.row.urgedList.map((item,index) => {
                       return h('div',{
                           style: {
                               lineHeight: '40px',
                               borderBottom: index == params.row.urgedList.length -1 ? '' :'1px solid #e8eaec'
                           }
                       },this.$core.formatDate(new Date(item.gmtCreate),'yyyy-MM-dd hh:mm:ss'))
                    })
                }else {
                    return h('div','无')
                }
            }
        },
        {
          title: "操作",
          fixed: "right",
          width: 120,
          align: "center",
          render: (h, params) => {
             return h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                    // disabled: params.row.isCreateOrder
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.getSeeData({
                        applyId: params.row.applyId,
                        orgCode: params.row.orgCode
                      })
                    },
                  },
                },
                params.row.isCreateOrder ? '已创建工单' : "创建工单"
              )
          }
        }
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,

    //   服务清单列表
    serviceList: [],

    serviceStatusList: [],

    // 创建工单
      openCreateStatus: false,
      createFormData: { serviceOrder: "1",orderType: '15' },
      createValidateObj: {
        name: [{ required: true, message: "请输入工单名称", trigger: "blur" }],
        content: [
          { required: true, message: "请填写服务要求", trigger: "blur" },
          {
            type: "string",
            max: 400,
            message: "请控制在400字以内",
            trigger: "blur",
          },
        ],
        serviceResult: [
          { required: true, message: "请填写服务记录", trigger: "blur" },
        ],
        orderType: [
          { required: true, message: "请选择工单类别", trigger: "change" },
        ],
        serviceOrder: [
          { required: true, message: "请选择是否派单", trigger: "change" },
        ],
        services: [
          {
            required: true,
            min: 1,
            type: "array",
            message: "请选择服务内容",
            trigger: "change",
          },
        ],
        userId: [
          {
            required: true,
            min: 1,
            message: "请选择服务对象",
            trigger: "change",
          },
        ],
      },
      selectServiceList: [],
      currentCreateStatus: false,
      errorServices: "",
      selectServiceOption: [],
      createTalbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "名称",
          minWidth: 200,
          key: "itemName",
        },
        {
          title: "简介",
          minWidth: 200,
          key: "introduction",
        },
        {
          title: "单价",
          width: 120,
          key: "price",
        },
        {
          title: "单位",
          width: 120,
          key: "priceUnit",
        },
        {
          title: "数量",
          width: 190,
          render: (h, params) => {
            return h("InputNumber", {
              props: {
                controlsOutside: true,
                min: 0,
                value: this.selectServiceList[params.index].num,
              },
              on: {
                "on-change": (val) => {
                  this.selectServiceList[params.index].num = val;
                },
              },
            });
          },
        },
        {
          title: "价格",
          width: 120,
          render: (h, params) => {
            return h(
              "div",
              this.selectServiceList[params.index].num * params.row.price
            );
          },
        },
      ],
       // 创建工单 -- 选择服务对象
      serviceApplyList: [],
      serviceWarningList: [],
      serviceApplyColumns: [
        {
          title: 'ID',
          width: 200,
          key: 'applyId',
        },
        {
          title: "姓名",
          width: 200,
          key: "userName",
        },
        {
          title: "服务内容",
          minWidth: 200,
          key: "content",
        },
      ],
      serviceApplyLoading: false,
      serviceApplyHeight: '',
      serviceApplyPage: 1,
      serviceApplyPageSize: 20,
      serviceApplyTotal: 0,
      serviceApplyStatus: false,
    }
  },
  filters: {
     filterTime(val, that) {
      if (!val) return "";
      return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
  
   // 创建工单
    async openCreateStatusFn(status) {
      this.openCreateStatus = status;
      if (status) {
        this.errorServices = "";
      }
    },
    
    //设备预警/服务请求表格选择单行
    currentChange(newRow) {  
      this.serviceApplyUserId = newRow.userId;
    },
    serviceApplyStatusFn(status) {
      if(status) {
        this.serviceApplyHeight = window.innerHeight - 316 + 'px';
      }
      this.serviceApplyStatus = status;
    },
    saveServiceApply() {
      this.createFormData.userId = this.serviceApplyUserId;
      this.serviceApplyStatus = false;
    },
    async saveCreateFn() {
      if(this.currentCreateStatus) return;
      let validateStatus = await this.$refs.createForm.validate();
      if (this.selectServiceOption.length == 0) {
        this.errorServices = "请选择服务内容";
        return;
      }
      if (!validateStatus) return;
      this.currentCreateStatus = true;
      let params = {
        bindId: this.createFormData.applyId,
        content: this.createFormData.content,
        familyId: this.createFormData.familyId,
        name: this.createFormData.name,
        serviceOrder: this.createFormData.serviceOrder,
        orderType: '407',
        services: (() => {
          let result = [];
          this.selectServiceList.map((item) => {
            if (item.itemId) {
              result.push({
                itemId: item.itemId,
                num: item.num,
              });
            }
          });
          return result;
        })(),
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orderId: "",  //工单id/修改才传
        orgCode: "1",
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        serviceStatus: "1",
        serviceResult: this.createFormData.serviceOrder == '1' ? '' : this.createFormData.serviceResult,
        status: this.createFormData.serviceOrder == "1" ? "1" : "3",
        userId: this.createFormData.userId
      };
      let res = await this.$post(
        "/gateway/api/homecare/pc/Order/createOrder",
        params,
        { "Content-Type": "application/json" }
      );
      if (res.code == 200 && res.desc == "success") {
        this.$Message.success({
          background: true,
          content: "提交成功",
        });
        this.openCreateStatusFn(false);
        this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize,
        });
      } else {
        this.$Message.error({
          background: true,
          content:
            res.desc && res.desc.length < 50
              ? res.desc
              : "提交失败，请联系管理员处理",
        });
      }
      this.currentCreateStatus = false;
    },
    createTableSelect(arr) {
      this.selectServiceOption = arr;
      let arrId = [];
      arr.map((item) => {
        arrId.push(item.itemId);
      });

      this.selectServiceList.map((item) => {
        if (arrId.indexOf(item.id) != -1) {
          item.itemId = arrId[arrId.indexOf(item.id)];
        } else {
          item.itemId = "";
        }
      });
      if (arr.length > 0) this.errorServices = "";
    },
    // 获取查看数据
    async getSeeData(params) {
      this.$Message.loading({
        content: '正在加载数据，请稍等...',
        duration: 0
      });
      let res = await this.$post('/gateway/api/homecare/pc/Order/selectServiceApplyByOrgCodeAndApplyId',params);
      this.$Message.destroy();
      if(res.code == 200 && res.data) {
        //处理服务清单列表回显
        let itemIDArr = [];
        let itemAmountArr = [];
        if(res.data.serviceApplyItemList) {
          res.data.serviceApplyItemList.map(item => {
            itemIDArr.push(item.itemId);
            itemAmountArr.push(item.amount);
          })
        }
        this.selectServiceOption = [];
        this.selectServiceList = [];
        this.serviceList.map((item) => {
          if(itemIDArr.indexOf(item.itemId) != -1) {
            this.selectServiceOption.push(item);
            this.selectServiceList.push({
              id: item.itemId,
              itemId: item.itemId,
              num: itemAmountArr[itemIDArr.indexOf(item.itemId)] || 0,
            });
            item['_checked'] = true;
          }else {
            this.selectServiceList.push({
              id: item.itemId,
              itemId: '',
              num: 0,
            });
          }
        });
        this.createFormData = {
          ...res.data,
          serviceOrder: '1'
        };
        this.openCreateStatus = true;
      }else {
        this.$Message.error({
              background: true,
              content:
                res.desc && res.desc.length < 20
                  ? res.desc
                  : "获取数据失败，请联系管理员处理",
            });
      }
    },
    // 获取服务状态字典
   async getServiceStatus() {
     let res = await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList",{
        dictType: "SERVICE_STATUS",
        appCode: 'HOMECARE',
      });
    if(res.code == 200 && res.dataList) {
      this.serviceStatusList = res.dataList;
    }
    },
      filterService(arr) {
      let result = [];
      if (arr && arr.length > 0) {
        this.serviceList.map((item) => {
          arr.map((subItem) => {
            if (item.itemId == subItem.itemId) {
              result.push({
                ...item,
                oderMoney: subItem.oderMoney,
                amount: subItem.amount
              });
            }
          });
        });
      }
      return result;
    },
    // 获取服务内容/服务清单列表
    async getServiceList() {
      let res = await this.$get(
        "/gateway/api/homecare/pc/OrderOrgItem/selectOrderByOrgCode",
        {
          // orgCode: parent.vue.loginInfo.userinfo.orgCode,
          orgCode: 1,
          page: 1,
          pageSize: 20,
        }
      );
      if (res.code == 200 && res.dataList) {
        this.serviceList = res.dataList;
      }
      return;
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/gateway/api/homecare/pc/Order/selectServcieApplyByOrgCode", {
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        orgCode: 1,
        page: obj.page,
        pageSize: obj.pageSize,
      })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.dataList || [];
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.page = res.currentPage;
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content:
                res.desc && res.desc.length < 20
                  ? res.desc
                  : "获取数据失败，请联系管理员处理",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
 async created() {
    await this.getServiceStatus();
     await this.getServiceList();
      this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>

<style lang="less" scoped>
/deep/.table-list {
  &>div {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}

.see-modal {
  background: #eee;
  width: 100%;
  height: calc(100vh - 172px);
  overflow: hidden;
  .content {
    padding: 10px;
    height: calc(100vh - 207px);
    overflow: auto;
  }
  .card {
    .ivu-card-body {
      & > div {
        line-height: 40px;
      }
      .list {
        .seeTable {
          margin-left: 1em;
        }
      }
    }
    .tf-title {
      color: #515a6e;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.services {
  /deep/.ivu-form-item-label {
    width: calc(100% - 10px) !important;
    text-align: left;
    margin-left: 10px;
  }
  /deep/.ivu-form-item-content {
    margin-left: 10px !important;
  }
  /deep/.validate {
    &::before {
      content: "*";
      display: inline-block;
      margin-right: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }
}
</style>